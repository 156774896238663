// Base URL for the API
const baseURL = process.env.REACT_APP_API_URL || 'http://3.7.0.186/api/v1';

// Create a custom fetch function
export const fetchWithAuth = async (url, options = {}) => {
  const publicEndpoints = ['/auth/verify-otp', '/auth/login', '/auth/resend-otp']; // Add Authorization header if not a public endpoint
  if (!publicEndpoints.includes(url)) {
    const token = localStorage.getItem('token');
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    } else {
      return Promise.reject(new Error('No token found in local storage'));
    }
  }

  // Ensure headers are properly set
  options.headers = {
    'Content-Type': 'application/json',
    ...options.headers,
  };

  try {
    const response = await fetch(`${baseURL}${url}`, options);

    // Handle response errors
    if (!response.ok) {
      const errorData = await response.json().catch(() => {
        return Promise.reject(new Error('Failed to parse error response'));
      });

      if (response.status === 401) {
        localStorage.removeItem('token');
        // toast.error('Session expired. Please login again.', {type: 'danger'});
      }

      return errorData;
    }

    return response.json(); // Assuming the response is in JSON format
  } catch (error) {
    // Check if the error has a response and a message
    if (error.response && error.response.data && error.response.data.message) {
      return error.response;
    }
    return Promise.reject(error); // Re-throw the error if you need to handle it further up the call stack
  }
};
