// Loader.js
import React from 'react';
import './style/loader.css'; // Create a separate CSS file for styling

const Loader = ({ loading }) => {
  if (!loading) return null; // Return null if not loading

  return (
    <div className="loader-container">
      <div className="spinner"></div>
    </div>
  );
};

export default Loader;
