import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import ResetPassword from './components/auth/ResetPassword';
import Login from './components/auth/Login';
import OtpScreen from './components/auth/OTP';
import Dashboard from './components/dashboard/Dashboard';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';

const App = () => {

  const auth = useSelector((state) => state.auth);
  const isAuthenticated = auth?.isAuthenticated;
  const showOTP = auth?.showOTP;

  return (
    <>
      <ToastContainer />
      <Router>
        <Routes>
          <Route
            path="/"
            element={isAuthenticated ? <Navigate to="/dashboard" /> : <Login />}
          />
          <Route
            path="/verify-otp"
            element={isAuthenticated ? <Navigate to="/dashboard" /> : showOTP ? <OtpScreen initialTime={90}/> : <Navigate to="/" /> }
          />
          <Route
            path="/dashboard"
            element={isAuthenticated ? <Dashboard /> : <Navigate to="/" />}
          />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
