import React, { useState } from "react";
import "./style/auth.css"; // We'll use a shared CSS for both login and register screens
import { toast } from "react-toastify";
import { loginUser } from "../../services/authService";
import Loader from "../commons/Loader"; // Import your loader
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { otp } from '../../redux/features/authSlice'; // Importing actions

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate(); // Initialize navigate

    const dispatch = useDispatch();

    console.log(process.env.REACT_APP_API_URL);
    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (!email || !password) {
            setError("All fields are required.");
        } else {
            try {
                const response = await loginUser({ email, password });

                if (response && response.state === "OTP_SENT_SUCCESSFULLY") {
                    dispatch(otp(response)); // Dispatch login action
                    setLoading(false);
                    setError("");
                    toast.success(response.message);
                    setEmail("");
                    setPassword("");
                    navigate("/verify-otp", { state: response }); // Navigate to OTP screen
                } else {
                    setLoading(false);
                    const errorMessage =
                        response?.data?.message || "Login failed. Please try again later.";
                    setError(errorMessage);
                }
            } catch (error) {
                setLoading(false);
                console.error("Login error:", error);
                setError("Login failed. Please try again later.");
            }
        }
    };

    return (
        <>
            <Loader loading={loading} />
            <div className="main">
                <div className="auth-container">
                    <form className="auth-form" onSubmit={handleLogin}>
                        <h2>Admin Login {process.env.REACT_APP}</h2>

                        {error && <div className="error-message">{error}</div>}

                        <div className="input-group">
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter your email"
                            // required
                            />
                        </div>

                        <div className="input-group">
                            <label htmlFor="password">Password</label>
                            <input
                                type="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Enter your password"
                            // required
                            />
                        </div>

                        <button type="submit" className="auth-button">
                            Login
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default Login;
