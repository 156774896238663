// src/OtpScreen.js
import React, { useState, useEffect } from "react";
import "./style/otp.css"; // Import the CSS file for styling
import { useNavigate } from "react-router-dom";
import { resendVerificationCode, verifyOTP } from "../../services/authService";
import { toast } from "react-toastify";
import Loader from "../commons/Loader";
import { login } from "../../redux/features/authSlice";
import { useDispatch, useSelector } from "react-redux";

const OtpScreen = ({ initialTime }) => {
    const navigate = useNavigate(); // Initialize navigate

    const { userId, otpExpiresInSeconds } = useSelector((state) => state.auth);

    const [otp, setOtp] = useState(["", "", "", "", "", ""]); // An array to hold the OTP digits
    const [timeLeft, setTimeLeft] = useState(parseInt(otpExpiresInSeconds)); // Time left for OTP
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        // Start timer if timeLeft is greater than 0
        if (timeLeft > 0) {
            const timer = setInterval(() => {
                setTimeLeft((prevTime) => prevTime - 1);
            }, 1000);

            // Clear interval on component unmount
            return () => clearInterval(timer);
        }
    }, [timeLeft]);

    const handleChange = (e, index) => {
        const value = e.target.value;

        // Allow only numbers and limit input to 1 character
        if (/^\d*$/.test(value) && value.length <= 1) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            // Focus on the next input if the current one is filled
            if (value && index < otp.length - 1) {
                document.getElementById(`otp-input-${index + 1}`).focus();
            }
        }
    };

    const handleKeyDown = (e, index) => {
        // Allow navigation with Backspace and Arrow keys
        if (e.key === "Backspace" && !otp[index] && index > 0) {
            document.getElementById(`otp-input-${index - 1}`).focus();
        }
        if (e.key === "ArrowRight" && index < otp.length - 1) {
            document.getElementById(`otp-input-${index + 1}`).focus();
        }
        if (e.key === "ArrowLeft" && index > 0) {
            document.getElementById(`otp-input-${index - 1}`).focus();
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);
        const enteredOtp = otp.join("");
        if (!userId) {
            setLoading(false);
            toast.error("User ID is required to verify OTP");
            return;
        }
        if (enteredOtp.length !== 6) {
            setLoading(false);
            toast.error("OTP should be 6 digits");
            return;
        }
        console.log("Submitted OTP:", enteredOtp);
        try {
            const response = await verifyOTP({ userId, otp: enteredOtp });
            if (response && response.state === "LOGIN_SUCCESSFUL") {
                localStorage.setItem("token", response.token);
                toast.success(response.message);
                dispatch(login(response)); // Dispatch login action
                navigate("/dashboard");
            } else {
                setLoading(false);
                toast.error(response.message);
            }
        } catch (error) {
            setLoading(false);
            console.error("Error while verifying OTP:", error);
            toast.error("Something went wrong. Please try again later.");
        }
    };

    const handleResendOtp = async (e) => {
        e.preventDefault();

        setLoading(true);
        if (!userId) {
            setLoading(false);
            toast.error("User ID is required to resend OTP");
            return;
        }

        try {
            setOtp(["", "", "", "", "", ""]); // Reset OTP fields
            const response = await resendVerificationCode({ userId });
            if (response && response.state === "OTP_RESENT_SUCCESSFULLY") {
                toast.success(response.message);
                setTimeLeft(otpExpiresInSeconds); // Reset the timer
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            console.error("Error while resending OTP:", error);
            toast.error("Something went wrong. Please try again later.");
        }
        setLoading(false);
    };

    // Check if all OTP fields are filled
    const isOtpFilled = otp.every((digit) => digit !== "");

    return (
        <>
            <Loader loading={loading} />
            <div className="otp-container">
                <h2>Enter OTP</h2>
                <form onSubmit={handleSubmit} className="otp-form">
                    {otp.map((digit, index) => (
                        <input
                            key={index}
                            type="text"
                            id={`otp-input-${index}`}
                            value={digit}
                            onChange={(e) => handleChange(e, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            maxLength="1"
                            className="otp-input"
                        />
                    ))}
                    <button type="submit" className="otp-submit" disabled={!isOtpFilled}>
                        Verify OTP
                    </button>
                </form>
                <div className="otp-timer">
                    {timeLeft > 0 ? (
                        <p>Time left: {timeLeft} seconds</p>
                    ) : (
                        <p>OTP expired!</p>
                    )}
                </div>
                {timeLeft <= 0 && (
                    <p className="otp-resend-link">
                        Didn't receive OTP?{" "}
                        <a
                            href="javascript(void(0))"
                            onClick={handleResendOtp}
                            className="otp-resend"
                            style={{
                                pointerEvents: timeLeft > 0 ? "none" : "auto",
                                color: timeLeft > 0 ? "#ccc" : "#007bff",
                            }} // Disable link styling
                        >
                            Resend OTP
                        </a>
                    </p>
                )}
            </div>
        </>
    );
};

export default OtpScreen;
