const initialState = {
  isAuthenticated: false,
  token: null,
  showOTP: false,
  userId: null,
  otpExpiresInSeconds: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return { isAuthenticated: true, showOTP: false, ...action.payload };

    case "OTP":
      return {
        isAuthenticated: false,
        token: null,
        showOTP: true,
        ...action.payload,
      };

    case "LOGOUT":
      return {
        isAuthenticated: false,
        token: null,
        showOTP: false,
        userId: null,
        otpExpiresInSeconds: null,
      };

    default:
      return state;
  }
};
// Action Creators
export const login = (response) => ({
  type: "LOGIN",
  payload: response,
});

export const otp = (state) => ({
  type: "OTP",
  payload: state,
});

export const logout = () => ({
  type: "LOGOUT",
});

export default authReducer;
