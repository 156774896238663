// services.js
import { fetchWithAuth } from './service'; // Make sure the path is correct

export const loginUser = async data => {
  return fetchWithAuth('/auth/login', {
    method: 'POST',
    body: JSON.stringify(data),
  });
};

export const verifyOTP = async data => {
  return fetchWithAuth('/auth/verify-otp', {
    method: 'POST',
    body: JSON.stringify(data),
  });
};

export const resendVerificationCode = async data => {
  return fetchWithAuth('/auth/resend-otp', {
    method: 'POST',
    body: JSON.stringify(data),
  });
};

export const logoutUser = async () => {
  return fetchWithAuth('/auth/logout', {
    method: 'POST',
  });
};

export const resendVerificationEmail = async data => {
  return fetchWithAuth('/resend-verification-email', {
    method: 'POST',
    body: JSON.stringify(data),
  });
};

export const forgotPassword = async data => {
  return fetchWithAuth('/auth/forgot-password', {
    method: 'POST',
    body: JSON.stringify(data),
  });
};

export const resetPassword = async data => {
  return fetchWithAuth('/auth/reset-password', {
    method: 'POST',
    body: JSON.stringify(data),
  });
};
