// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { combineReducers } from 'redux';
import authReducer from './features/authSlice';

// Step 1: Combine Reducers
const rootReducer = combineReducers({
  auth: authReducer,
});

// Step 2: Configure Persist
const persistConfig = {
  key: 'root',  // Key to store persisted data
  storage,      // Type of storage (localStorage)
};

// Step 3: Create Persisted Reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Step 4: Configure the Store with Persisted Reducer
const store = configureStore({
  reducer: persistedReducer,
});

// Step 5: Create Persistor
export const persistor = persistStore(store);

export default store;
