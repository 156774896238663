import React from 'react';
import './style/dashboard.css'; // Assuming you're using CSS for styling
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { logout } from '../../redux/features/authSlice';
import { logoutUser } from '../../services/authService';
import { toast } from 'react-toastify';

const Dashboard = () => {

  const { userId } = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    const response = logoutUser();
    if (response && response.state === 'LOGGED_OUT_SUCCESSFULLY') { // Handle response if needed
      console.log('Logout successful');
      dispatch(logout()); // Dispatch logout action
      navigate('/');
    }else{
      toast(response.message);
    }
    
  };

  return (
    <div className="dashboard-container">
      {/* Sidebar */}
      <div className="sidebar">
        <h2>Dashboard</h2>
        <ul>
          <li onClick={() => navigate('/dashboard/home')}>Home</li>
          <li onClick={() => navigate('/dashboard/profile')}>Profile</li>
          <li onClick={() => navigate('/dashboard/settings')}>Settings</li>
        </ul>
        <button onClick={handleLogout}>Logout</button>
      </div>

      {/* Main Content */}
      <div className="main-content">
        <div className="header">
          <h1>Welcome to the Dashboard</h1>
          <p>Manage your account and settings</p>
        </div>

        {/* Replace this with dynamic components */}
        <div className="content">
          <h2>Overview</h2>
          <p>This is where your dashboard content goes.</p>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
